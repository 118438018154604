<template>
  <InteriorLayout type="review" :hasSearch="false">
    <div class="interior-review-wrapper">
      <div class="image relative">
        <vueper-slides
          class="no-shadow"
          :bullets="false"
          :autoplay="true"
          :arrowsOutside="true"
          :touchable="false"
          fixed-height="305px"
        >
          <vueper-slide v-for="(i, index) in images" :key="index">
            <template #content>
              <div @click="openViewer" class="vueperslide__content-wrapper">
                <img :src="i" />
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>

      <div class="content">
        <div class="flex flex-row items-center justify-center mb-10">
          <div class="flex flex-col mr-10">
            <p class="user">{{ name }}</p>
            <p class="date">{{ date }}</p>
          </div>

          <StarRating
            :read-only="true"
            :show-rating="false"
            :star-size="40"
            :active-color="'#293bf0'"
            :rating="rating"
          />
        </div>

        <hr />

        <div class="review-content">
          <p>{{ content }}</p>
        </div>

        <hr />

        <div class="office flex flex-row mt-6 items-center justify-between">
          <div class="flex flex-row mt-6 items-center">
            <img :src="office.image" class="office_img w-14 h-14 rounded-full mr-4" />
            <div>
              <p class="office_name">{{ office.name }}</p>
              <p class="office_explanation">{{ office.explanation }}</p>
            </div>
          </div>

          <button
            @click="goToDetail"
            class="flex flex-row items-center justify-center office-detail"
          >
            <eva-icon
              name="clipboard-outline"
              :width="20"
              :height="20"
              :fill="'#fff'"
            />
            상세보기
          </button>
        </div>
      </div>

      <!-- <div class="detail">
        <h1>인테리어 업체의 다른 후기</h1>
        <p class="explain">
          {{ data.explanationDetail }}
        </p>
      </div> -->
    </div>

    <div class="hidden">
      <ImageViewer :images="images" />
    </div>
  </InteriorLayout>
</template>

<script>
import ImageViewer from "@/components/ImageViewer.vue";
import InteriorLayout from "@/components/InteriorLayout";
import services from "@/services";
import utils from "@/utils";
import dayjs from "dayjs";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import StarRating from "vue-star-rating";

export default {
  name: "InteriorReviewDetail",
  components: {
    InteriorLayout,
    VueperSlides,
    VueperSlide,
    ImageViewer,
    StarRating,
  },
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const { id } = this.$route.params;
      const data = await services.interiorReviewDetail(id);
      this.data = data;
      this.isFavorite = !!this.subscribe;
    },
    goToDetail() {
      this.$router.push({
        name: "InteriorOfficeDetail",
        params: {
          id: this.data.id,
        },
      });
    },
    openViewer() {
      this.$viewerApi({
        images: this.images,
      });
    },
  },
  computed: {
    images() {
      if (this.data) {
        const { hasTradeReviewFiles } = this.data;
        if (hasTradeReviewFiles) {
          return hasTradeReviewFiles.map((i) => utils.getImageUrl(i));
        }
      }

      return [];
    },
    name() {
      if (this.data) {
        const { hasUser } = this.data;
        if (hasUser) {
          return hasUser.nickname;
        }
      }

      return "";
    },
    date() {
      if (this.data) {
        const { createdAt } = this.data;
        return dayjs(createdAt).format("YYYY.MM.DD");
      }

      return "";
    },
    rating() {
      if (this.data) {
        return this.data.rating;
      }

      return 0;
    },
    content() {
      return this.data?.contents ?? "";
    },
    office() {
      if (this.data) {
        const { hasInteriorJoinOffer } = this.data;
        if (hasInteriorJoinOffer) {
          const { hasInteriorOffice } = hasInteriorJoinOffer;
          const { id, title, explanation, hasThumbnail } = hasInteriorOffice;

          return {
            id,
            image: hasThumbnail ? utils.getImageUrl(hasThumbnail) : null,
            name: title,
            explanation,
          };
        }
      }

      return {
        id: -1,
        image: null,
        name: "",
        explanation: "",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.interior-review-wrapper {
  margin-top: 90px;
  margin-bottom: 190px;

  .image {
    height: 305px;
    margin: 0 60px 20px;
    i {
      top: 10px;
      right: 10px;
    }
  }

  .content {
    margin-top: 40px;
  }

  .user {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    color: #222;
  }

  .review-content {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #717171;
  }

  .office_name {
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    color: #222;
  }

  .office_explanation {
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    color: #7ca3aa;
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.22px;
    text-align: left;
    color: #222;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .explain {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7ca3aa;
  }

  .date {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.32px;
    text-align: left;
    color: #717171;
  }

  .hint {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.28px;
    text-align: left;
    color: #b0b0b0;
    margin-top: 40px;
  }

  .detail {
    margin-top: 70px;
  }

  .office-detail {
    width: 145px;
    height: 50px;
    border-radius: 10px;
    background-color: #293bf0;

    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.64px;
    text-align: left;
    color: #fff;
  }

  .portfolio {
    margin-top: 70px;
  }

  .slide-wrapper {
    height: 305px;
  }
}

@media (max-width: 639px) {
  .interior-review-wrapper {
    margin-top: 50px;
    margin-bottom: 100px;
    .image {
      height: 200px;
      margin: 0 30px 20px;
      .vueperslides {
        height: 100% !important;
        .vueperslide {
          height: 100% !important;
        }
      }
    }
    .user {
      font-size: 12px;
    }
    .date {
      font-size: 11px;
    }
    .review-content {
      font-size: 11px;
    }
    .office_img {
      width: 35px;
      height: 35px;
    }
    .office_name {
      font-size: 11px;
    }
    .office_explanation {
      font-size: 11px;
    }
    .office-detail {
      width: 100px;
      height: 35px;
      font-size: 11px;
      line-height: 1.3;
    }
    .office {
      &>div {
        margin-top: 0;
      }
    }
  }
}
</style>
