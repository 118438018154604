<template>
  <viewer :images="images">
    <img v-for="(src, index) in images" :key="index" :src="src" />
  </viewer>
</template>

<script>
import "viewerjs/dist/viewer.css";

export default {
  name: "ImageViewer",
  props: {
    images: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style></style>
